/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  useSpacing,
  ThemeProvider,
  audiDarkTheme,
  audiLightTheme,
  Button,
  SpacingShorthandList,
  Theme,
  Text,
  SpacingProps,
} from '@audi/audi-ui-react-v2';
import {
  COLOR_BASE_BRAND_BLACK,
  COLOR_BASE_BRAND_WHITE,
  SPACING_XS_XS,
  BREAKPOINT_XS,
  BREAKPOINT_L,
  BREAKPOINT_M,
  BREAKPOINT_S,
  BREAKPOINT_XL,
  BREAKPOINT_XXL,
  PAGE_MARGIN_XS,
  PAGE_MARGIN_S,
  PAGE_MARGIN_M,
  PAGE_MARGIN_L,
  PAGE_MARGIN_XL,
  PAGE_MARGIN_XXL,
} from '@audi/audi-ui-design-tokens';
import styled from 'styled-components';
import { useContent } from '@oneaudi/feature-app-utils';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import type { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { EditorJsonContent, FalconContent, mapContent } from '../contentMapping/contentMapping';

import { convertRichTextWithFootnotes, isDarkTheme, isDebugMode } from '../utils';
import type {
  EditorialTeaserContent,
  EditorialTeaserComponent,
  EditorialTeaserServices,
  ThemeColor,
  FeatureAppMeta,
} from '../../types';
import { TrackingClickEvent, useTracking } from '../tracking';
import { LegalInfo } from './LegalInfo';
import { APP_ID } from '../../environment';

const TeaserContainer = styled.div<{
  spacing: SpacingShorthandList;
  numberOfComponents: number;
}>`
  box-sizing: border-box;
  display: grid;
  &:first-child {
    margin-block-start: ${({ theme }: { theme: Theme }) =>
      `var(${theme.responsive?.spacing.xxxl})`};
  }
  &:last-child {
    margin-block-end: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.xxxl})`};
  }
  margin-inline-start: ${PAGE_MARGIN_XS}px;
  margin-inline-end: ${PAGE_MARGIN_XS}px;

  @media screen and (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ numberOfComponents }) => {
      if (numberOfComponents === 1) {
        return `
            grid-template-areas:
            "a a"
            "b c";
          grid-gap: ${SPACING_XS_XS}px;
          grid-auto-columns: 1fr;
            `;
      }
      return '';
    }}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.s}px) {
    margin-inline-start: ${PAGE_MARGIN_S}px;
    margin-inline-end: ${PAGE_MARGIN_S}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    margin-inline-start: ${PAGE_MARGIN_M}px;
    margin-inline-end: ${PAGE_MARGIN_M}px;
    ${({ numberOfComponents }) => {
      if (numberOfComponents === 1 || numberOfComponents === 3) {
        return `
            grid-template-areas:
            "a a"
            "b c";
          grid-gap: ${SPACING_XS_XS}px;
          grid-auto-columns: 1fr;
            `;
      }
      if (numberOfComponents === 2) {
        return `
            grid-template-columns: 1fr 1fr;
            grid-gap: ${SPACING_XS_XS}px;
            `;
      }
      return '';
    }}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.l}px) {
    margin-inline-start: ${PAGE_MARGIN_L}px;
    margin-inline-end: ${PAGE_MARGIN_L}px;
    ${({ numberOfComponents }) => {
      if (numberOfComponents === 1) {
        return `
            grid-template-areas:
            "a a"
            "b c";
          grid-gap: ${SPACING_XS_XS}px;
          grid-auto-columns: 1fr;
            `;
      }
      if (numberOfComponents === 2) {
        return `
            grid-template-columns: 1fr 1fr;
            grid-gap: ${SPACING_XS_XS}px;
            `;
      }

      if (numberOfComponents === 3) {
        return `
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: ${SPACING_XS_XS}px;
            `;
      }
      return '';
    }}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xl}px) {
    margin-inline-start: ${PAGE_MARGIN_XL}px;
    margin-inline-end: ${PAGE_MARGIN_XL}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xxl}px) {
    margin-inline-start: ${PAGE_MARGIN_XXL}px;
    margin-inline-end: ${PAGE_MARGIN_XXL}px;
  }
`;

const StyledComponent = styled.div<{
  index: number;
  gridArea: string;
  numberOfComponents: number;
}>`
  ${({ numberOfComponents, gridArea }) => {
    if (numberOfComponents === 1) {
      return `
        flex-direction: column;
        grid-area: ${gridArea};
      `;
    }
    return '';
  }}

  .component__inner {
    display: flex;
    height: auto;
    flex-direction: column;
    height: 100%;
  }

  .component__inner--linked {
    cursor: pointer;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.xs}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m - 1}px) {
    ${({ theme, index }: { theme: Theme; index: number }) =>
      index > 0
        ? `
      margin-block-start: var(${theme.responsive.spacing.xl});
`
        : ''}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.l - 1}px) {
    ${({ numberOfComponents, gridArea }) => {
      if (numberOfComponents === 3) {
        return `
        flex-direction: column;
        grid-area: ${gridArea};
            `;
      }
      return '';
    }}
  }

  a {
    text-decoration: none !important;
  }
`;

const Media = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;

  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
  }

  img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.screen-size-xs {
      @media screen and (min-width: ${BREAKPOINT_XS}px) and (max-width: ${BREAKPOINT_S - 1}px) {
        display: block;
      }
    }

    &.screen-size-s {
      @media screen and (min-width: ${BREAKPOINT_S}px) and (max-width: ${BREAKPOINT_M - 1}px) {
        display: block;
      }
    }

    &.screen-size-m {
      @media screen and (min-width: ${BREAKPOINT_M}px) and (max-width: ${BREAKPOINT_L - 1}px) {
        display: block;
      }
    }

    &.screen-size-l {
      @media screen and (min-width: ${BREAKPOINT_L}px) and (max-width: ${BREAKPOINT_XL - 1}px) {
        display: block;
      }
    }

    &.screen-size-xl {
      @media screen and (min-width: ${BREAKPOINT_XL}px) and (max-width: ${BREAKPOINT_XXL - 1}px) {
        display: block;
      }
    }

    &.screen-size-xxl {
      @media screen and (min-width: ${BREAKPOINT_XXL}px) {
        display: block;
      }
    }
  }
`;

const TextArea = styled.div`
  height: 100%;
  background-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? COLOR_BASE_BRAND_BLACK : COLOR_BASE_BRAND_WHITE};

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.xs}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m - 1}px) {
    ${({ theme }: { theme: Theme }) =>
      isDarkTheme(theme)
        ? `
        padding-block-start: var(${theme.responsive.spacing.m});
        padding-inline-end: var(${theme.responsive.spacing.l});
        padding-block-end: var(${theme.responsive.spacing.m});
        padding-inline-start: var(${theme.responsive.spacing.l});
  `
        : `
        padding-block-start: var(${theme.responsive.spacing.m});
        `}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme }: { theme: Theme }) =>
      isDarkTheme(theme)
        ? `
        padding-block-start: var(${theme.responsive.spacing.l});
        padding-inline-end: var(${theme.responsive.spacing.xl});
        padding-block-end: var(${theme.responsive.spacing.l});
        padding-inline-start: var(${theme.responsive.spacing.xl});
  `
        : `
        padding-block-start: var(${theme.responsive.spacing.m});
        padding-inline-end: var(${theme.responsive.spacing.xl});
        `}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xxl}px) {
    ${({ theme }: { theme: Theme }) =>
      isDarkTheme(theme)
        ? `
        padding-block-start: var(${theme.responsive.spacing.m});
        padding-inline-end: var(${theme.responsive.spacing.l});
        padding-block-end: var(${theme.responsive.spacing.m});
        padding-inline-start: var(${theme.responsive.spacing.l});
  `
        : `
        padding-block-start: var(${theme.responsive.spacing.m});
        padding-inline-end: var(${theme.responsive.spacing.xl});
        `}
  }
`;

const ButtonContainer = styled.div`
  margin-block-start: ${({ theme }: { theme: Theme }) =>
    `calc(-1 * var(${theme.responsive?.spacing.m}))`};

  &:first-child {
    margin-block-start: 0;
  }
`;

const SmallButtonContainer = styled.div`
  height: 46px;
  display: none;

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.xs}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m - 1}px) {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;

const MediumButtonContainer = styled.div`
  height: 58px;
  display: none;

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;

const TEXT_SPACING_LIGHT: SpacingProps = {
  spaceStackStart: 'm',
  spaceStackEnd: 's',
};

const TEXT_SPACING_DARK: SpacingProps = {
  spaceStackStart: 'l',
  spaceStackEnd: 'l',
};

const Component = ({
  themeColor,
  content: {
    heading,
    headingTag,
    text,
    image,
    links: unfilteredLinks,
    consumptionAndEmissions,
    disclaimers,
    originalIndex,
  },
  index,
  numberOfComponents,
  sendClickEvent,
  vueFormatterService,
  localeService,
}: {
  themeColor?: ThemeColor;
  content: ChunkedEditorialTeaserComponent;
  index: number;
  numberOfComponents: number;
  sendClickEvent?: (event: TrackingClickEvent, componentIndex: number) => void;
  vueFormatterService: VueFormatterServiceInterfaceV1;
  localeService: LocaleServiceV1;
}): React.ReactElement => {
  const hasLegalInfo = !!consumptionAndEmissions?.length || !!disclaimers?.length;
  // eslint-disable-next-line no-nested-ternary
  const gridArea = index === 0 ? 'a' : index === 1 ? 'b' : 'c';

  // remove links without label
  const links =
    unfilteredLinks && unfilteredLinks.length ? unfilteredLinks.filter((link) => link.label) : [];

  // entire component is only clickable if just one link is defined:
  const componentTargetURL = links?.length === 1 && links[0].url ? links[0].url : undefined;
  const componentOpenInNewTab = Boolean(links?.length === 1 && links[0].openInNewTab);
  const [isCSR, setIsCSR] = React.useState(false);

  React.useEffect(() => {
    setIsCSR(true);
  }, []);

  const handleComponentClick = (targetURL?: string) => {
    if (sendClickEvent)
      sendClickEvent(
        {
          ...(targetURL ? { targetURL } : { action: 'image' }),
          name: 'click on teaser',
          elementName: 'image',
          value: heading,
          pos: `${originalIndex + 1}`,
        },
        originalIndex
      );
  };
  const handleTextLinkClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    targetURL: string,
    linkLabel: string
  ) => {
    e.stopPropagation();
    if (sendClickEvent)
      sendClickEvent(
        {
          name: 'click on teaser',
          elementName: 'text link',
          value: heading,
          pos: `${originalIndex + 1}`,
          targetURL,
          label: linkLabel,
        },
        originalIndex
      );
  };

  const body = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      style={{ height: '100%' }}
      className={`component__inner${componentTargetURL ? ' component__inner--linked' : ''}`}
      onClick={() => handleComponentClick(componentTargetURL)}
    >
      <Media>
        <div>
          <img
            className="screen-size-xs screen-size-s screen-size-m screen-size-l screen-size-xl screen-size-xxl"
            src={image.src}
            alt={image.alt}
          />
        </div>
      </Media>
      <TextArea>
        {heading && (
          <Text variant="order3" as={headingTag || 'h3'}>
            {isCSR && convertRichTextWithFootnotes(heading)}
          </Text>
        )}

        {text && (
          <Text {...(themeColor === 'dark' ? TEXT_SPACING_DARK : TEXT_SPACING_LIGHT)}>
            {isCSR && convertRichTextWithFootnotes(text)}
          </Text>
        )}

        {Boolean(links && links.length) && (
          <div>
            {links &&
              links.map((link) => (
                <ButtonContainer>
                  <SmallButtonContainer>
                    <Button
                      variant="text"
                      key={link.label}
                      aria-label={link.ariaLabel || ''}
                      newWindow={link.openInNewTab}
                      href={link.url}
                      onClick={(e) => handleTextLinkClick(e, link.url, link.label)}
                    >
                      {link.label}
                    </Button>
                  </SmallButtonContainer>
                  <MediumButtonContainer>
                    <Button
                      variant="text"
                      key={link.label}
                      aria-label={link.ariaLabel || ''}
                      newWindow={link.openInNewTab}
                      href={link.url}
                      onClick={(e) => handleTextLinkClick(e, link.url, link.label)}
                    >
                      {link.label}
                    </Button>
                  </MediumButtonContainer>
                </ButtonContainer>
              ))}
          </div>
        )}

        {hasLegalInfo && (
          <LegalInfoContainer>
            <LegalInfo
              consumptionAndEmissions={consumptionAndEmissions || []}
              disclaimers={disclaimers}
              vueFormatterService={vueFormatterService}
              localeService={localeService}
            />
          </LegalInfoContainer>
        )}
      </TextArea>
    </div>
  );

  return (
    <StyledComponent
      gridArea={gridArea}
      numberOfComponents={numberOfComponents}
      index={originalIndex}
    >
      {componentTargetURL ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          style={{ height: '100%' }}
          onClick={() =>
            window.open(componentTargetURL, componentOpenInNewTab ? '_blank' : '_self')
          }
        >
          {body}
        </div>
      ) : (
        body
      )}
    </StyledComponent>
  );
};

const LegalInfoContainer = styled.div``;

export const EditorialTeaser = ({
  components,
  themeColor = 'light',
  vueFormatterService,
  localeService,
  meta,
  chunkedComponentsSSR,
}: EditorialTeaserContent &
  EditorialTeaserServices & {
    meta: FeatureAppMeta;
    chunkedComponentsSSR: any;
  }): React.ReactElement => {
  const chunkedComponents: any = chunkedComponentsSSR || sliceComponentsIntoChunks(components, 3);
  const ref = React.useRef(null);
  const teaserContainerSpacing = useSpacing({ spaceStackStart: 'xxxl', spaceStackEnd: 'xxxl' });
  let relatedElements: any;
  if (typeof window !== 'undefined') {
    relatedElements = React.useRef(
      components.map((x, i) => ({
        elementName: 'teaser',
        elementValue: `${i} - ${
          x.heading || `${x.text ? `${x.text?.slice(0, 10)}...` : '(no text)'}`
        }`,
      }))
    );
  }
  const {
    registerImpressionTracking,
    sendReadyEvent,
    sendClickEvent: _sendClickEvent,
  } = useTracking(meta.id, {
    // implementer is Modus Create
    implementer: 37,
  });
  const sendClickEvent = React.useCallback(
    (event: TrackingClickEvent, componentIndex: number) => {
      _sendClickEvent({
        ...event,
        relatedElements: [relatedElements.current[componentIndex]],
      });
    },
    [_sendClickEvent]
  );

  React.useEffect(() => sendReadyEvent(), []);

  React.useEffect(() => {
    if (ref.current)
      registerImpressionTracking(ref.current, {
        relatedElements: relatedElements.current,
      });
  }, [ref]);

  return (
    <ThemeProvider theme={themeColor === 'dark' ? audiDarkTheme : audiLightTheme}>
      <div ref={ref} data-testid="editorial-teaser">
        {chunkedComponents?.map((chunk: ChunkedEditorialTeaserComponent[]) => (
          <TeaserContainer spacing={teaserContainerSpacing} numberOfComponents={chunk.length}>
            {chunk?.map((chunkedComponent: ChunkedEditorialTeaserComponent, i: number) => (
              <Component
                key={[chunkedComponent.originalIndex, chunkedComponent.heading].join('_')}
                themeColor={themeColor}
                index={i}
                content={chunkedComponent}
                numberOfComponents={chunk.length}
                sendClickEvent={sendClickEvent}
                vueFormatterService={vueFormatterService}
                localeService={localeService}
              />
            ))}
          </TeaserContainer>
        ))}
      </div>
    </ThemeProvider>
  );
};

export const EditorialTeaserContentLoader = ({
  initialContent,
  localeService,
  vueFormatterService,
  meta,
  ssrContent,
  chunkedComponents,
}: {
  meta: FeatureAppMeta;
  initialContent?: EditorialTeaserContent;
  ssrContent: EditorialTeaserContent;
  chunkedComponents: ChunkedEditorialTeaserComponent[];
} & EditorialTeaserServices): React.ReactElement | null => {
  let content: EditorJsonContent;
  if (!ssrContent) {
    const rawContent = useContent<EditorJsonContent | FalconContent>() || initialContent;
    // eslint-disable-next-line no-console
    content = mapContent(rawContent as EditorJsonContent | FalconContent);
  } else {
    content = ssrContent;
  }

  if (isDebugMode())
    // eslint-disable-next-line no-console
    console.debug(`${APP_ID} ->`, {
      content,
    });

  if (!content) return null;

  return (
    <EditorialTeaser
      {...content}
      localeService={localeService}
      vueFormatterService={vueFormatterService}
      meta={meta}
      chunkedComponentsSSR={chunkedComponents}
    />
  );
};

interface ChunkedEditorialTeaserComponent extends EditorialTeaserComponent {
  // additional prop for remembering the index from the original
  // array of components before chunking it. Only used for
  // tracking purposes.
  originalIndex: number;
}

/**
 * Put teaser components data in a chunked array so that
 * more than 3 columns can be rendered
 */
function sliceComponentsIntoChunks(
  components: EditorialTeaserComponent[],
  chunkSize: number
): ChunkedEditorialTeaserComponent[][] {
  const chunkedComponents: ChunkedEditorialTeaserComponent[][] = [];
  let chunk: ChunkedEditorialTeaserComponent[] = [];

  if (typeof window !== 'undefined') {
    components.forEach((component, componentIndex) => {
      chunk.push({ ...component, originalIndex: componentIndex });

      if (chunk.length >= chunkSize) {
        chunkedComponents.push(chunk);
        chunk = [];
      }
    });

    if (chunk.length) {
      chunkedComponents.push(chunk);
    }
  }

  return chunkedComponents;
}
