/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lone-blocks */
import * as React from 'react';
import type {
  FeatureAppDefinition,
  FeatureAppEnvironment,
  FeatureServices,
} from '@feature-hub/core';
import type { ReactFeatureApp } from '@feature-hub/react';
import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import type { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { ContentContextProvider, FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import type { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { EditorialTeaserContentLoader } from './components/EditorialTeaser';
import {
  ChunkedEditorialTeaserComponent,
  EditorialTeaserComponent,
  EditorialTeaserContent,
} from '../types';
import { EditorJsonContent, FalconContent, mapContent } from './contentMapping/contentMapping';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi-tracking-service': TrackingServiceV2;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
}

{
  // allow setting ssr mode via URL, so it's set before anything launches
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);
    let ssr = false;
    if (searchParams.has('ssr')) {
      const ssrQueryParam = searchParams.get('ssr') as string;
      ssr = ssrQueryParam === 'true';
      // eslint-disable-next-line no-console
      console.debug(`Query parameter 'ssr' recognized: ${ssr}`);
    }

    // eslint-disable-next-line no-underscore-dangle
    (window as any).__SSR__ = ssr;
  }
}

function sliceComponentsIntoChunks(
  components: EditorialTeaserComponent[],
  chunkSize: number
): ChunkedEditorialTeaserComponent[][] {
  const chunkedComponents: ChunkedEditorialTeaserComponent[][] = [];
  let chunk: ChunkedEditorialTeaserComponent[] = [];

  components.forEach((component, componentIndex) => {
    chunk.push({ ...component, originalIndex: componentIndex });
    if (chunk.length >= chunkSize) {
      chunkedComponents.push(chunk);
      chunk = [];
    }
  });

  if (chunk.length) {
    chunkedComponents.push(chunk);
  }

  return chunkedComponents;
}

export const getAsyncStateHolder = async (contentService: ContentServiceV1) => {
  const rawContent = await contentService?.getContent();
  const content = mapContent(rawContent as EditorJsonContent | FalconContent);
  const chunkedComponents = sliceComponentsIntoChunks(content?.components, 3);
  return { content, chunkedComponents };
};

// eslint-disable-next-line import/no-default-export
const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    externals: {
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '5.1.1',
      '@audi/audi-ui-react-v2': '^2.1.0',
      '@feature-hub/react': '^2.9.0',
    },
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'locale-service': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },

  create: ({
    featureServices: {
      'audi-content-service': contentService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      'locale-service': localeService,
      'dbad:audi-vue-formatter-service': vueFormatterService,
      's2:async-ssr-manager': asyncSSRManager,
      's2:serialized-state-manager': serializedStateManager,
    },
    config,
    featureAppId: id,
  }: FeatureAppEnvironment<FeatureServiceDependencies, EditorialTeaserContent>) => {
    let asyncStateHolder: any;

    // SSR
    // --------------------------------------------------------------------
    // on the server
    if (asyncSSRManager) {
      asyncSSRManager.scheduleRerender(
        (async () => {
          asyncStateHolder = await getAsyncStateHolder(contentService);
          serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
        })()
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        asyncStateHolder = JSON.parse(serializedState);
      } else {
        asyncStateHolder = () => getAsyncStateHolder(contentService);
      }
    }

    return {
      render: () => {
        return (
          <>
            {asyncStateHolder && (
              <ContentContextProvider contentService={contentService}>
                <FootnoteContextProvider
                  /* eslint-disable-next-line max-len */
                  footnoteReferenceService={footnoteReferenceServiceScopeManager?.getDefaultScopeRefService()}
                >
                  <EditorialTeaserContentLoader
                    vueFormatterService={vueFormatterService}
                    localeService={localeService}
                    initialContent={config}
                    ssrContent={asyncStateHolder.content}
                    meta={{ id }}
                    chunkedComponents={asyncStateHolder.chunkedComponents}
                  />
                </FootnoteContextProvider>
              </ContentContextProvider>
            )}
          </>
        );
      },
    };
  },
};
export default featureAppDefinition;
